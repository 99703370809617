const dialogRegistry = {
  ConfirmationDialog: defineAsyncComponent(
    () => import("~/shared/dialog/components/confirmation-dialog.vue"),
  ),
  DocumentPdfViewer: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/document/components/document-pdf-viewer-dialog.vue"
      ),
  ),
} satisfies Record<string, ReturnType<typeof defineAsyncComponent>>;

export type DialogId = keyof typeof dialogRegistry;

export { dialogRegistry };
